import { pages as settings } from 'subapps/settings/routes'
import * as onboarding from 'subapps/onboarding/pages'

export const AUTH_CALLBACK_PAGE = 'AUTH_CALLBACK_PAGE'
export const FOLDER_PAGE = 'FOLDER_PAGE'
export const LOGIN_PAGE = 'LOGIN_PAGE'
export const LOGIN_SELECTOR = 'LOGIN_SELECTOR'
export const LOGIN_MULTIPLE_ACCOUNTS_PAGE = 'LOGIN_MULTIPLE_ACCOUNTS_PAGE'
export const LOGIN_AGENT_MISSING_PAGE = 'LOGIN_AGENT_MISSING_PAGE'
export const LOGOUT_PAGE = 'LOGOUT_PAGE'
export const FORGOT_SUBDOMAIN_PAGE = 'FORGOT_SUBDOMAIN_PAGE'
export const FORGOT_PASSWORD_PAGE = 'FORGOT_PASSWORD_PAGE'
export const CHANGE_PASSWORD_PAGE = 'CHANGE_PASSWORD_PAGE'
export const OTP_CHALLENGE_PAGE = 'OTP_CHALLENGE_PAGE'
export const MAILBOX_DEFAULT_PAGE = 'MAILBOX_DEFAULT_PAGE'
export const MAILBOX_FOLDER_PAGE = 'MAILBOX_FOLDER_PAGE'
export const MAILBOX_PAGE = 'MAILBOX_PAGE'
export const MAIN_PAGE = 'MAIN_PAGE'
export const RAW_EMAIL_PAGE = 'RAW_EMAIL_PAGE'
export const SEARCH_PAGE = 'SEARCH_PAGE'
export const SETTINGS_PAGE = 'SETTINGS_PAGE'
export const TICKET_COMMENT_PAGE = 'TICKET_COMMENT_PAGE'
export const TICKET_FORWARD_CHANGESET_PAGE = 'TICKET_FORWARD_CHANGESET_PAGE'
export const TICKET_FORWARD_PAGE = 'TICKET_FORWARD_PAGE'
export const TICKET_PAGE = 'TICKET_PAGE'
export const TICKETS_PAGE = 'TICKETS_PAGE'
export const TICKET_REPLY_CHANGESET_PAGE = 'TICKET_REPLY_CHANGESET_PAGE'
export const TICKET_REPLY_DIRECT_CHANGESET_PAGE =
  'TICKET_REPLY_DIRECT_CHANGESET_PAGE'
export const TICKET_REPLY_DIRECT_PAGE = 'TICKET_REPLY_DIRECT_PAGE'
export const TICKET_REPLY_PAGE = 'TICKET_REPLY_PAGE'
export const TICKET_CHANGESET_PAGE = 'TICKET_CHANGESET_PAGE'

export const NEW_CONVERSATION_PAGE = 'NEW_CONVERSATION_PAGE'
export const LOG_CONVERSATION_PAGE = 'LOG_CONVERSATION_PAGE'

export const OAUTH_ERROR_PAGE = 'OAUTH_ERROR_PAGE'

module.exports = {
  ...module.exports,
  ...settings,
  ...onboarding,
}
