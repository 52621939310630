export const NOOP = 'NOOP'

export const LOGOUT = 'LOGOUT'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_CHALLENGE = 'LOGIN_CHALLENGE'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const TOKEN_REFRESH_REQUEST = 'TOKEN_REFRESH_REQUEST'
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS'
export const TOKEN_REFRESH_FAILURE = 'TOKEN_REFRESH_FAILURE'

export const ADD_RETURN_TO = 'ADD_RETURN_TO'
export const CLEAR_RETURN_TO = 'CLEAR_RETURN_TO'

export const AUTO_REDIRECT = 'AUTO_REDIRECT'
export const DEPRECATED_CHANGE_PATH = 'DEPRECATED_CHANGE_PATH'
export const SAVE_RETURN_PATH = 'SAVE_RETURN_PATH'
export const CLEAR_RETURN_PATH = 'CLEAR_RETURN_PATH'
export const UPDATE_CURRENT_USER_WITH_MAILBOXES =
  'UPDATE_CURRENT_USER_WITH_MAILBOXES'
export const UPDATE_APP_DATA = 'UPDATE_APP_DATA'
export const UPDATE_REPORT_SETTINGS = 'UPDATE_REPORT_SETTINGS'
export const ACCOUNT_PREFS_CHANGED = 'ACCOUNT_PREFS_CHANGED'
export const UPDATE_APP_DEVICE_TYPE = 'UPDATE_APP_DEVICE_TYPE'
export const FETCHED_INBOX_DATA = 'FETCHED_INBOX_DATA'

export const CONVERT_MAILBOX_TO_DEMO_REQUEST = 'CONVERT_MAILBOX_TO_DEMO_REQUEST'
export const CONVERT_MAILBOX_TO_DEMO_SUCCESS = 'CONVERT_MAILBOX_TO_DEMO_SUCCESS'
export const CONVERT_MAILBOX_TO_DEMO_FAILURE = 'CONVERT_MAILBOX_TO_DEMO_FAILURE'

export const UPDATE_ACCESS_LIST = 'UPDATE_ACCESS_LIST'
export const REMOVE_ACCESS_LIST = 'REMOVE_ACCESS_LIST'
export const UPDATE_ACTIVE_LABEL_INDEX = 'UPDATE_ACTIVE_LABEL_INDEX'

export const FETCH_FOLDER_COUNTS_REQUEST = 'FETCH_FOLDER_COUNTS_REQUEST'
export const FETCH_FOLDER_COUNTS_SUCCESS = 'FETCH_FOLDER_COUNTS_SUCCESS'
export const FETCH_FOLDER_COUNTS_FAIL = 'FETCH_FOLDER_COUNTS_FAIL'
export const FETCH_FOLDER_COUNTS_COMPLETE = 'FETCH_FOLDER_COUNTS_COMPLETE'

export const LIST_SCROLL_TOP_CHANGED = 'LIST_SCROLL_TOP_CHANGED'

export const KEY_PRESSED_CHANGE = 'KEY_PRESSED_CHANGE'
export const MARK_BULK_SELECTION_MODE = 'MARK_BULK_SELECTION_MODE'
export const UNMARK_BULK_SELECTION_MODE = 'UNMARK_BULK_SELECTION_MODE'
export const TOGGLE_TICKET_SELECTION = 'TOGGLE_TICKET_SELECTION'
export const RANGED_TICKET_SELECTION = 'RANGED_TICKET_SELECTION'

export const SELECT_TICKET = 'SELECT_TICKET'
export const DESELECT_TICKET = 'DESELECT_TICKET'
export const DESELECT_ALL_TICKETS = 'DESELECT_ALL_TICKETS'
export const UPDATE_TICKETS = 'UPDATE_TICKETS'

export const SEARCH_COMPLETE = 'SEARCH_COMPLETE'

export const SEARCHES = 'SEARCHES'
export const SEARCHES_REQUEST = 'SEARCHES_REQUEST'
export const SEARCHES_SUCCESS = 'SEARCHES_SUCCESS'
export const SEARCHES_FAIL = 'SEARCHES_FAIL'

export const SEARCH_USER_REQUEST = 'SEARCH_USER_REQUEST'
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS'
export const SEARCH_USER_FAIL = 'SEARCH_USER_FAIL'
export const SEARCH_USER_COMPLETE = 'SEARCH_USER_COMPLETE'

export const CLEAR_USER_SUGGESTION = 'CLEAR_USER_SUGGESTION'

export const UPDATE_CURRENT_TICKET_SEARCH_QUERY =
  'UPDATE_CURRENT_TICKET_SEARCH_QUERY'

export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR'
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR'

export const OPEN_CHANGE_INBOX_MENU = 'OPEN_CHANGE_INBOX_MENU'
export const CLOSE_CHANGE_INBOX_MENU = 'CLOSE_CHANGE_INBOX_MENU'

export const OPEN_MORE_TICKET_OPTIONS_MENU = 'OPEN_MORE_TICKET_OPTIONS_MENU'
export const CLOSE_MORE_TICKET_OPTIONS_MENU = 'CLOSE_MORE_TICKET_OPTIONS_MENU'

export const UPDATE_MODAL_PROPS = 'UPDATE_MODAL_PROPS'

export const UPDATE_LABEL_SEARCH_RESULTS = 'UPDATE_LABEL_SEARCH_RESULTS'
export const SEARCH_LABELS_REQUEST = 'SEARCH_LABELS_REQUEST'
export const CLEAR_LABEL_SEARCH_TERM = 'CLEAR_LABEL_SEARCH_TERM'
export const CLEAR_LABEL_SEARCH_RESULTS = 'CLEAR_LABEL_SEARCH_RESULTS'
export const MAILBOX_LABELS_REQUEST = 'MAILBOX_LABELS_REQUEST'
export const MAILBOX_LABELS_SUCCESS = 'MAILBOX_LABELS_SUCCESS'
export const MAILBOX_LABELS_FAIL = 'MAILBOX_LABELS_FAIL'

export const STAR_TICKETS_STARTED = 'STAR_TICKETS_STARTED'
export const STAR_TICKETS_SUCCESS = 'STAR_TICKETS_SUCCESS'

export const DELETE_TICKETS = 'DELETE_TICKETS'
export const DELETE_TICKETS_STARTED = 'DELETE_TICKETS_STARTED'
export const DELETE_TICKETS_SUCCESS = 'DELETE_TICKETS_SUCCESS'
export const DELETE_TICKETS_FAIL = 'DELETE_TICKETS_FAIL'

export const TOGGLE_TICKETS_SORTING = 'TOGGLE_TICKETS_SORTING'
export const TOGGLE_SHOW_CC_BCC = 'TOGGLE_SHOW_CC_BCC'

export const MARK_FETCHING_STATUS = 'MARK_FETCHING_STATUS'
export const CHANGE_MAILBOX_SWITCHER_VISIBILITY =
  'CHANGE_MAILBOX_SWITCHER_VISIBILITY'
export const SELECT_TICKETS = 'SELECT_TICKETS'

export const ADD_ACTION = 'ADD_ACTION'
export const ADD_CHANGESET = 'ADD_CHANGESET'
export const ADD_TICKET = 'ADD_TICKET'

export const FETCH_TICKET_REQUEST = 'FETCH_TICKET_REQUEST'
export const FETCH_TICKET_SUCCESS = 'FETCH_TICKET_SUCCESS'
export const FETCH_TICKET_FAIL = 'FETCH_TICKET_FAIL'

export const FETCH_TICKET_ACTIONS_SUCCESS = 'FETCH_TICKET_ACTIONS_SUCCESS'
export const FETCH_TICKET_ACTIONS_FAIL = 'FETCH_TICKET_ACTIONS_FAIL'

export const FETCH_TICKET_LINKED_RESOURCES_FAIL =
  'FETCH_TICKET_LINKED_RESOURCES_FAIL'
export const FETCH_TICKET_LINKED_RESOURCES = 'FETCH_TICKET_LINKED_RESOURCES'
export const FETCH_TICKET_LINKED_RESOURCES_REQUEST =
  'FETCH_TICKET_LINKED_RESOURCES_REQUEST'
export const FETCH_TICKET_LINKED_RESOURCES_SUCCESS =
  'FETCH_TICKET_LINKED_RESOURCES_SUCCESS'

export const FETCH_CHANGESET_TICKET_ACTIONS_REQUEST =
  'FETCH_CHANGESET_TICKET_ACTIONS_REQUEST'
export const FETCH_CHANGESET_TICKET_ACTIONS_SUCCESS =
  'FETCH_CHANGESET_TICKET_ACTIONS_SUCCESS'
export const FETCH_CHANGESET_TICKET_ACTIONS_FAIL =
  'FETCH_CHANGESET_TICKET_ACTIONS_FAIL'

export const BULK_FETCH_CHANGESET_TICKET_ACTIONS_REQUEST =
  'BULK_FETCH_CHANGESET_TICKET_ACTIONS_REQUEST'
export const BULK_FETCH_CHANGESET_TICKET_ACTIONS_SUCCESS =
  'BULK_FETCH_CHANGESET_TICKET_ACTIONS_SUCCESS'

export const CREATE_CHANGESET_REQUEST = 'CREATE_CHANGESET_REQUEST'
export const CREATE_CHANGESET_SUCCESS = 'CREATE_CHANGESET_SUCCESS'
export const CREATE_CHANGESET_FAIL = 'CREATE_CHANGESET_FAIL'

export const CREATE_BULK_CHANGESET_REQUEST = 'CREATE_BULK_CHANGESET_REQUEST'
export const CREATE_BULK_CHANGESET_SUCCESS = 'CREATE_BULK_CHANGESET_SUCCESS'
export const CREATE_BULK_CHANGESET_FAIL = 'CREATE_BULK_CHANGESET_FAIL'
export const CREATE_BULK_CHANGESET_COMPLETE = 'CREATE_BULK_CHANGESET_COMPLETE'
export const CREATE_TICKET_REQUEST = 'CREATE_TICKET_REQUEST'
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS'
export const CREATE_TICKET_FAIL = 'CREATE_TICKET_FAIL'
export const CREATE_TICKET_COMPLETE = 'CREATE_TICKET_COMPLETE'

export const ASSIGN_TICKET_TO_AGENT = 'ASSIGN_TICKET_TO_AGENT'
export const ASSIGN_TICKETS_TO_AGENT = 'ASSIGN_TICKETS_TO_AGENT'

export const REALTIME_NOTE_UPDATE = 'REALTIME_NOTE_UPDATE'
export const UPDATE_RAW_EMAIL = 'UPDATE_RAW_EMAIL'
export const UPDATE_NOTE_FORM_HEIGHT = 'UPDATE_NOTE_FORM_HEIGHT'
export const SET_TICKET_ASSIGNMENT_FILTER = 'SET_TICKET_ASSIGNMENT_FILTER'
export const SET_TICKET_GROUP_ASSIGNMENT_FILTER =
  'SET_TICKET_GROUP_ASSIGNMENT_FILTER'

export const SNOOZE_TICKETS_REQUEST = 'SNOOZE_TICKETS_REQUEST'
export const SNOOZE_TICKETS_SUCCESS = 'SNOOZE_TICKETS_SUCCESS'
export const SNOOZE_TICKETS_COMPLETE = 'SNOOZE_TICKETS_COMPLETE'
export const UPDATE_SELECTED_SNOOZE_STATE = 'UPDATE_SELECTED_SNOOZE_STATE'
export const SAVE_LAST_SNOOZED_DATE = 'SAVE_LAST_SNOOZED_DATE'

export const UPDATE_REALTIME_STATUS = 'UPDATE_REALTIME_STATUS'
export const UPDATE_TICKET_DIRTY_STATUS = 'UPDATE_TICKET_DIRTY_STATUS'

export const TICKET_TITLE_EDITING_START = 'TICKET_TITLE_EDITING_START'
export const TICKET_TITLE_EDITING_STOP = 'TICKET_TITLE_EDITING_STOP'
export const TICKET_TITLE_UPDATE_REQUEST = 'TICKET_TITLE_UPDATE_REQUEST'
export const TICKET_TITLE_DRAFT_CHANGED = 'TICKET_TITLE_DRAFT_CHANGED'

export const ATTACHMENT_UPLOAD_STARTED = 'ATTACHMENT_UPLOAD_STARTED'
export const ATTACHMENT_UPLOAD_PROGRESSED = 'ATTACHMENT_UPLOAD_PROGRESSED'
export const ATTACHMENT_UPLOAD_FINISHED = 'ATTACHMENT_UPLOAD_FINISHED'
export const ATTACHMENT_UPLOAD_FAILED = 'ATTACHMENT_UPLOAD_FAILED'
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT'

// Modals
export const GROUP_DRILL_DOWN = 'GROUP_DRILL_DOWN'

export const QUEUE_EDITOR_TOOLBAR_MESSAGE = 'QUEUE_EDITOR_TOOLBAR_MESSAGE'
export const PROCESS_NEXT_EDITOR_TOOLBAR_MESSAGE =
  'PROCESS_NEXT_EDITOR_TOOLBAR_MESSAGE'
export const CLEAR_EDITOR_TOOLBAR_MESSAGES = 'CLEAR_EDITOR_TOOLBAR_MESSAGES'

export const SET_JUST_CLOSED_KEYBOARD = 'SET_JUST_CLOSED_KEYBOARD'

export const UPDATE_INACCESSIBLE_MAILBOXES = 'UPDATE_INACCESSIBLE_MAILBOXES'

export const FETCH_OPTIMISTIC_MERGE_TICKETS_REQUEST =
  'FETCH_OPTIMISTIC_MERGE_TICKETS_REQUEST'
export const FETCH_OPTIMISTIC_MERGE_TICKETS_SUCCESS =
  'FETCH_OPTIMISTIC_MERGE_TICKETS_SUCCESS'

export const FETCH_TOP_LABELS_REQUEST = 'FETCH_TOP_LABELS_REQUEST'
export const FETCH_TOP_LABELS_SUCCESS = 'FETCH_TOP_LABELS_SUCCESS'

export const FETCH_TICKET_SNIPPETS_REQUEST = 'FETCH_TICKET_SNIPPETS_REQUEST'
export const FETCH_TICKET_SNIPPETS_SUCCESS = 'FETCH_TICKET_SNIPPETS_SUCCESS'
export const FETCH_TICKET_SNIPPETS_FAIL = 'FETCH_TICKET_SNIPPETS_FAIL'
export const UPDATE_MERGE_SNIPPETS_VISIBILITY =
  'UPDATE_MERGE_SNIPPETS_VISIBILITY'

export const WEB_PUSH_SUBSCRIPTION_CREATE = 'WEB_PUSH_SUBSCRIPTION_CREATE'
export const WEB_PUSH_SUBSCRIPTION_CREATED = 'WEB_PUSH_SUBSCRIPTION_CREATED'
export const WEB_PUSH_SUBSCRIPTION_CREATE_FAIL =
  'WEB_PUSH_SUBSCRIPTION_CREATE_FAIL'

export const WEB_PUSH_SUBSCRIPTION_LOAD = 'WEB_PUSH_SUBSCRIPTION_LOAD'
export const WEB_PUSH_SUBSCRIPTION_LOADED = 'WEB_PUSH_SUBSCRIPTION_LOADED'
export const WEB_PUSH_SUBSCRIPTION_LOAD_FAIL = 'WEB_PUSH_SUBSCRIPTION_LOAD_FAIL'

export const FETCH_WEB_PUSH_SUBSCRIPTIONS_REQUEST =
  'FETCH_WEB_PUSH_SUBSCRIPTIONS_REQUEST'
export const FETCH_WEB_PUSH_SUBSCRIPTIONS_SUCCESS =
  'FETCH_WEB_PUSH_SUBSCRIPTIONS_SUCCESS'
export const FETCH_WEB_PUSH_SUBSCRIPTIONS_FAIL =
  'FETCH_WEB_PUSH_SUBSCRIPTIONS_FAIL'

export const WEB_PUSH_SUBSCRIBE = 'WEB_PUSH_SUBSCRIBE'
export const WEB_PUSH_SUBSCRIBE_COMPLETE = 'WEB_PUSH_SUBSCRIBE_COMPLETE'
export const WEB_PUSH_SUBSCRIBE_FAIL = 'WEB_PUSH_SUBSCRIBE_FAIL'

export const SAVE_WEB_PUSH_SUBSCRIPTION_REQUEST =
  'SAVE_WEB_PUSH_SUBSCRIPTION_REQUEST'
export const SAVE_WEB_PUSH_SUBSCRIPTION_SUCCESS =
  'SAVE_WEB_PUSH_SUBSCRIPTION_SUCCESS'
export const SAVE_WEB_PUSH_SUBSCRIPTION_FAIL = 'SAVE_WEB_PUSH_SUBSCRIPTION_FAIL'

export const WEB_PUSH_UNSUBSCRIBE = 'WEB_PUSH_UNSUBSCRIBE'
export const WEB_PUSH_UNSUBSCRIBE_COMPLETE = 'WEB_PUSH_UNSUBSCRIBE_COMPLETE'
export const WEB_PUSH_UNSUBSCRIBE_FAIL = 'WEB_PUSH_UNSUBSCRIBE_FAIL'

export const DELETE_WEB_PUSH_SUBSCRIPTION_REQUEST =
  'DELETE_WEB_PUSH_SUBSCRIPTION_REQUEST'
export const DELETE_WEB_PUSH_SUBSCRIPTION_SUCCESS =
  'DELETE_WEB_PUSH_SUBSCRIPTION_SUCCESS'
export const DELETE_WEB_PUSH_SUBSCRIPTION_FAIL =
  'DELETE_WEB_PUSH_SUBSCRIPTION_FAIL'

export const WEB_PUSH_RESUBSCRIBE = 'WEB_PUSH_RESUBSCRIBE'

export const UPDATE_AGENT = 'UPDATE_AGENT'
export const REMOVE_AGENT = 'REMOVE_AGENT'

export const FETCH_EMBEDDABLE_CARD_CONTENT_FETCHING =
  'FETCH_EMBEDDABLE_CARD_CONTENT_FETCHING'
export const FETCH_EMBEDDABLE_CARD_CONTENT_RECEIVED =
  'FETCH_EMBEDDABLE_CARD_CONTENT_RECEIVED'
export const FETCH_EMBEDDABLE_CARD_CONTENT_ERRORED =
  'FETCH_EMBEDDABLE_CARD_CONTENT_ERRORED'
export const TRIGGER_EMBEDDABLE_CARD_ERRORED = 'TRIGGER_EMBEDDABLE_CARD_ERRORED'
export const TRIGGER_EMBEDDABLE_CARD_SUCCESS = 'TRIGGER_EMBEDDABLE_CARD_SUCCESS'

export const UPDATE_CARD_LOADING = 'UPDATE_CARD_LOADING'
export const UPDATE_CARD_COLLAPSED = 'UPDATE_CARD_COLLAPSED'
export const UPDATE_CARD_HIDDEN = 'UPDATE_CARD_HIDDEN'
export const UPDATE_CARD_TAB_TITLE = 'UPDATE_CARD_TAB_TITLE'
export const ACTIVATE_CARD_TAB = 'ACTIVATE_CARD_TAB'
export const CARD_ON_CONTEXT_CHANGE_REGISTERED =
  'CARD_ON_CONTEXT_CHANGE_REGISTERED'

export const FETCH_TOP_ASSIGNMENTS_REQUEST = 'FETCH_TOP_ASSIGNMENTS_REQUEST'
export const FETCH_TOP_ASSIGNMENTS_SUCCESS = 'FETCH_TOP_ASSIGNMENTS_SUCCESS'
export const FETCH_TOP_ASSIGNMENTS_FAIL = 'FETCH_TOP_ASSIGNMENTS_FAIL'

export const FETCH_AGENTS_REQUEST = 'FETCH_AGENTS_REQUEST'
export const FETCH_AGENTS_SUCCESS = 'FETCH_AGENTS_SUCCESS'
export const FETCH_AGENTS_FAIL = 'FETCH_AGENTS_FAIL'

export const UPDATE_AGENT_MENTION = 'UPDATE_AGENT_MENTION'
export const UPDATE_MENTIONS = 'UPDATE_MENTIONS'
export const UPDATE_SELECTED_MENTION_SELECTOR_AGENT_ID =
  'UPDATE_SELECTED_MENTION_SELECTOR_AGENT_ID'
export const UPDATE_SUPPRESS_MENTION_SELECTOR =
  'UPDATE_SUPPRESS_MENTION_SELECTOR'

export const REMOVE_GHOST_TICKET = 'REMOVE_GHOST_TICKET'

export const LIGHTBOX_NEXT = 'LIGHTBOX_NEXT'
export const LIGHTBOX_PREVIOUS = 'LIGHTBOX_PREVIOUS'

export const TOGGLE_LIST_SEARCH_BOX_STATUS = 'TOGGLE_LIST_SEARCH_BOX_STATUS'

export const MAILBOX_SEARCH_INPUT = 'MAILBOX_SEARCH_INPUT'

export const LOG_NEW_CONVERSATION = 'LOG_NEW_CONVERSATION'
export const NEW_CONVERSATION_LOGGED = 'NEW_CONVERSATION_LOGGED'

export const TOGGLE_RIGHT_SIDEBAR = 'TOGGLE_RIGHT_SIDEBAR'

export const INTEGRATIONS_CLEAN_FORM_VALUES = 'INTEGRATIONS_CLEAN_FORM_VALUES'

export const INTEGRATIONS_UPDATE_FORM_VALUE = 'INTEGRATIONS_UPDATE_FORM_VALUE'
export const INTEGRATIONS_INTEGRATION_SELECTED =
  'INTEGRATIONS_INTEGRATION_SELECTED'
export const INTEGRATIONS_METHOD_CHANGED = 'INTEGRATIONS_METHOD_CHANGED'

export const INTEGRATION_FETCH_GITHUB_REPOS_REQUEST =
  'INTEGRATION_FETCH_GITHUB_REPOS_REQUEST'
export const INTEGRATION_FETCH_GITHUB_REPOS_SUCCESS =
  'INTEGRATION_FETCH_GITHUB_REPOS_SUCCESS'
export const INTEGRATION_FETCH_GITHUB_REPOS_FAILURE =
  'INTEGRATION_FETCH_GITHUB_REPOS_FAILURE'

export const INTEGRATION_FETCH_GITHUB_REPO_DETAILS_REQUEST =
  'INTEGRATION_FETCH_GITHUB_REPO_DETAILS_REQUEST'
export const INTEGRATION_FETCH_GITHUB_REPO_DETAILS_SUCCESS =
  'INTEGRATION_FETCH_GITHUB_REPO_DETAILS_SUCCESS'
export const INTEGRATION_FETCH_GITHUB_REPO_DETAILS_FAILURE =
  'INTEGRATION_FETCH_GITHUB_REPO_DETAILS_FAILURE'

export const INTEGRATION_FETCH_GITHUB_ISSUES_REQUEST =
  'INTEGRATION_FETCH_GITHUB_ISSUES_REQUEST'
export const INTEGRATION_FETCH_GITHUB_ISSUES_SUCCESS =
  'INTEGRATION_FETCH_GITHUB_ISSUES_SUCCESS'
export const INTEGRATION_FETCH_GITHUB_ISSUES_FAILURE =
  'INTEGRATION_FETCH_GITHUB_ISSUES_FAILURE'

export const INTEGRATION_CREATE_GITHUB_ISSUE_REQUEST =
  'INTEGRATION_CREATE_GITHUB_ISSUE_REQUEST'
export const INTEGRATION_CREATE_GITHUB_ISSUE_SUCCESS =
  'INTEGRATION_CREATE_GITHUB_ISSUE_SUCCESS'
export const INTEGRATION_CREATE_GITHUB_ISSUE_FAILURE =
  'INTEGRATION_CREATE_GITHUB_ISSUE_FAILURE'

export const INTEGRATIONS_ATTACH_TO_TICKET_REQUEST =
  'INTEGRATIONS_ATTACH_TO_TICKET_REQUEST'
export const INTEGRATIONS_ATTACH_TO_TICKET_SUCCESS =
  'INTEGRATIONS_ATTACH_TO_TICKET_SUCCESS'
export const INTEGRATIONS_ATTACH_TO_TICKET_FAILURE =
  'INTEGRATIONS_ATTACH_TO_TICKET_FAILURE'

export const INTEGRATION_SET_NEXT_STEP_DEPENDENCY_FIELDS =
  'INTEGRATION_SET_NEXT_STEP_DEPENDENCY_FIELDS'

export const SIDEBARCARD_FETCH_DATA_REQUEST = 'SIDEBARCARD_FETCH_DATA_REQUEST'
export const SIDEBARCARD_FETCH_DATA_SUCCESS = 'SIDEBARCARD_FETCH_DATA_SUCCESS'
export const SIDEBARCARD_FETCH_DATA_FAILURE = 'SIDEBARCARD_FETCH_DATA_FAILURE'
export const SIDEBARCARD_CHANGE_OPEN_STATE = 'SIDEBARCARD_CHANGE_OPEN_STATE'

export const INTEGRATION_FORM_SET_REQUIRED_FIELDS =
  'INTEGRATION_FORM_SET_REQUIRED_FIELDS'
export const INTEGRATION_FORM_SHOW_VALIDATION_ERRORS =
  'INTEGRATION_FORM_SHOW_VALIDATION_ERRORS'
export const EMAIL_MARKETING_SUBSCRIBE_SUCCESS =
  'EMAIL_MARKETING_SUBSCRIBE_SUCCESS'
export const EMAIL_MARKETING_SUBSCRIBE_FAILURE =
  'EMAIL_MARKETING_SUBSCRIBE_FAILURE'
export const EMAIL_MARKETING_SUBSCRIBE_REQUEST =
  'EMAIL_MARKETING_SUBSCRIBE_REQUEST'

export const EMAIL_MARKETING_UNSUBSCRIBE_SUCCESS =
  'EMAIL_MARKETING_UNSUBSCRIBE_SUCCESS'
export const EMAIL_MARKETING_UNSUBSCRIBE_FAILURE =
  'EMAIL_MARKETING_UNSUBSCRIBE_FAILURE'
export const EMAIL_MARKETING_UNSUBSCRIBE_REQUEST =
  'EMAIL_MARKETING_UNSUBSCRIBE_REQUEST'

export const EMAIL_MARKETING_GET_INTEGRATION_STATE_REQUEST =
  'EMAIL_MARKETING_GET_INTEGRATION_STATE_REQUEST'
export const EMAIL_MARKETING_GET_INTEGRATION_STATE_SUCCESS =
  'EMAIL_MARKETING_GET_INTEGRATION_STATE_SUCCESS'
export const EMAIL_MARKETING_GET_INTEGRATION_STATE_FAILURE =
  'EMAIL_MARKETING_GET_INTEGRATION_STATE_FAILURE'

export const EMAIL_MARKETING_FETCH_INTEGRATIONS_REQUEST =
  'EMAIL_MARKETING_FETCH_INTEGRATIONS_REQUEST'
export const EMAIL_MARKETING_FETCH_INTEGRATIONS_SUCCESS =
  'EMAIL_MARKETING_FETCH_INTEGRATIONS_SUCCESS'
export const EMAIL_MARKETING_FETCH_INTEGRATIONS_FAILURE =
  'EMAIL_MARKETING_GET_INTEGRATION_STATE_FAILURE'

export const CREATE_INVITATION_REQUEST = 'CREATE_INVITATION_REQUEST'
export const CREATE_INVITATION_SUCCESS = 'CREATE_INVITATION_SUCCESS'
export const CREATE_INVITATION_FAILURE = 'CREATE_INVITATION_FAILURE'

export const ACCEPT_INVITATION_REQUEST = 'ACCEPT_INVITATION_REQUEST'
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS'
export const ACCEPT_INVITATION_FAILURE = 'ACCEPT_INVITATION_FAILURE'

export const GET_INVITATION_REQUEST = 'GET_INVITATION_REQUEST'
export const GET_INVITATION_SUCCESS = 'GET_INVITATION_SUCCESS'
export const GET_INVITATION_FAILURE = 'GET_INVITATION_FAILURE'

export const SEARCH_MAILBOXES_UPDATE = 'SEARCH_MAILBOXES_UPDATE'
export const SEARCH_UPDATE_BY_KEY = 'SEARCH_UPDATE_BY_KEY'

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'

export const FORGOT_SUBDOMAIN_REQUEST = 'FORGOT_SUBDOMAIN_REQUEST'
export const FORGOT_SUBDOMAIN_SUCCESS = 'FORGOT_SUBDOMAIN_SUCCESS'
export const FORGOT_SUBDOMAIN_FAIL = 'FORGOT_SUBDOMAIN_FAIL'

export const LOAD_RESET_PASSWORD_REQUEST = 'LOAD_RESET_PASSWORD_REQUEST'
export const LOAD_RESET_PASSWORD_SUCCESS = 'LOAD_RESET_PASSWORD_SUCCESS'
export const LOAD_RESET_PASSWORD_FAIL = 'LOAD_RESET_PASSWORD_FAIL'

export const HIDE_LAUNCH_BAR = 'HIDE_LAUNCH_BAR'

export const TOGGLE_PRINT_MODE = 'TOGGLE_PRINT_MODE'

export const UPDATE_ACCOUNT = 'account/UPDATE_ACCOUNT'
export const UPDATE_ACCOUNT_STARTED = 'account/UPDATE_ACCOUNT_STARTED'
export const UPDATE_ACCOUNT_SUCCESS = 'account/UPDATE_ACCOUNT_SUCCESS'
export const UPDATE_ACCOUNT_FAILED = 'account/UPDATE_ACCOUNT_FAILED'

export const UPDATE_COMPOSER_VISIBILITY = 'UPDATE_COMPOSER_VISIBILITY'
export const UPDATE_COMPOSER_PINNED = 'UPDATE_COMPOSER_PINNED'
