import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsEditing } from 'ducks/inAppCards/customer'
import { updateCustomer } from 'ducks/customers/actions'
import { Icon } from 'util/ui'
import { doFetchTicket } from 'ducks/tickets/actions/doFetchTicket'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'

import useDoUpdateCustomFieldValuesFromForm from '../../../useDoUpdateCustomFieldValuesFromForm'
import ContactHeaderFieldsEditModal from './ContactHeaderFieldsEditModal'

export default function ContactHeaderFieldsEditButton({
  doRecalcMenuPosition,
  doSetMenu,
  firstName,
  lastName,
  menuRef,
  offset,
  openMenu,
  subjectId,
  type,
  onCancel,
}) {
  const dispatch = useDispatch()
  const ticketId = useSelector(selectCurrentTicketId)
  const isEditingCustomer = useSelector(selectIsEditing)
  const doOpen = useCallback(() => doSetMenu('edit'), [doSetMenu])
  const doClose = useCallback(
    e => {
      doSetMenu(null)
      if (onCancel) {
        onCancel(e)
      }
    },
    [doSetMenu, onCancel]
  )
  const onSave = useDoUpdateCustomFieldValuesFromForm(subjectId)

  const handleSave = useCallback(
    async formData => {
      if (isEditingCustomer) {
        const {
          contact_first_name: contactFirstName,
          contact_last_name: contactLastName,
          contact_email: contactEmail,
        } = formData
        await dispatch(
          updateCustomer({
            first_name: contactFirstName?.content,
            last_name: contactLastName?.content,
            email: contactEmail?.content,
          })
        )
      }
      await onSave(formData)
      dispatch(doFetchTicket(ticketId))
    },
    [onSave, isEditingCustomer, dispatch, ticketId]
  )

  return (
    <>
      <div className="action" onClick={doOpen}>
        <Icon name="pencil" size="small" />
      </div>
      {openMenu === 'edit' && (
        <ContactHeaderFieldsEditModal
          doRecalcMenuPosition={doRecalcMenuPosition}
          firstName={firstName}
          lastName={lastName}
          menuRef={menuRef}
          offset={offset}
          onCancel={doClose}
          onSave={handleSave}
          type={type}
        />
      )}
    </>
  )
}
