import { createSelector } from 'reselect'
import { selectAccountPreferenceCustomProfileAppInstalled } from 'selectors/app'
import { isFeatureEnabled } from 'ducks/tracking/actions'

import {
  selectCurrentContactIsPartiallyLoaded,
  selectCurrentContact,
  selectCurrentConversationCustomFieldGroupKey,
} from 'ducks/crm/contacts/selectors'
import {
  selectCurrentCompanyIsPartiallyLoaded,
  selectCurrentCompanyId,
} from 'ducks/crm/companies/selectors'

import { selectAllCustomFieldsIsLoading } from 'ducks/crm/customFields/selectors'
import {
  selectCustomFieldCategories,
  selectAllCustomFieldCategoriesIsLoading,
} from 'ducks/crm/customFieldCategories'
import {
  selectAccountPreferenceChatEnabled,
  selectPrefersAiEnabled,
} from 'selectors/app/selectAccountPreferences'
import { selectIsInChat } from 'selectors/location'
import { selectSidebarCardIntegrations } from 'selectors/sidebar_cards'
import { uniq } from 'util/arrays'
import {
  selectHasWidgetsWithBridged,
  selectIsChatEnabled,
} from 'ducks/widgets/selectors'
import { selectCurrentConversationIsSpam } from 'ducks/tickets/selectors/selectCurrentConversationIsSpam'
import { selectChannelCustomFieldCategoryGroups } from 'ducks/crm/channels/selectors/selectChannelCustomFieldCategoryGroups'

function selectUserAgentPreferenceSidebarCard(state) {
  const preference =
    state.agentPreferences.byId['contact_details_column:widget_cards']
  const { value } = preference || {}
  return (value && value.items) || []
}

export const selectSidebarCardVisibility = createSelector(
  selectUserAgentPreferenceSidebarCard,
  preferences => {
    return preferences.reduce((result, { key, visible }) => {
      // eslint-disable-next-line no-param-reassign
      result[key] = visible
      return result
    }, {})
  }
)

const DEFAULT_PRIORITY_ORDER = {
  'customWidget.ai': true,
}

const DEFAULT_ORDER = {
  'customWidget.missing_contact': false,
  'customFieldCategory.contact_information': true,
  'customWidget.recent_conversations': true,
  'customWidget.attachments': true,
  'customWidget.custom_profile': true,
  'customWidget.chat_insights': false,
  'customWidget.visit_history': false,
  'customWidget.browser_info': false,
  'customFieldCategory.contact_social': false,
  'customFieldCategory.company_information': false,
  'customFieldCategory.company_social': false,
  'customFieldCategory.contact_professional': false,
  'customFieldCategory.company_finances': false,
  'customFieldCategory.contact_education': false,
  'customFieldCategory.contact_sessions': false,
  'integration.atlassian_oauth2': false,
  'integration.jira_server': false,
  'integration.trello': false,
  'integration.hubspot': false,
  'integration.github': false,
  'integration.salesforce': false,
  'integration.shopify': false,
  'integration.shopify_v2': false,
  'integration.stripe': false,
  'integration.recharge': false,
  'integration.Mailchimp': false,
  'integration.ConstantContact': false,
  'integration.CampaignMonitor': false,
}

const makeCustomWidgets = (
  isChatEnabled,
  isChatPage,
  isCustomProfileInstalled,
  hasChatWidgets,
  hasWidgetsWithBridged,
  currentTicketIsSpam,
  isAiEnabled
) =>
  [
    hasWidgetsWithBridged && {
      id: 'browser_info',
      customWidget: true,
      name: 'Browser info',
      uiKey: 'customWidget.browser_info',
    },
    isChatEnabled &&
      hasChatWidgets && {
        id: 'chat_insights',
        customWidget: true,
        name: 'Chat insights',
        uiKey: 'customWidget.chat_insights',
      },
    {
      id: 'recent_conversations',
      customWidget: true,
      name: 'Recent conversations',
      uiKey: 'customWidget.recent_conversations',
    },
    (isFeatureEnabled('ai-overview') ||
      isFeatureEnabled('ai-overview-ready')) &&
      isAiEnabled &&
      !isChatPage &&
      !currentTicketIsSpam && {
        id: 'ai',
        customWidget: true,
        name: 'AI overview',
        uiKey: 'customWidget.ai',
      },
    !isChatPage && {
      id: 'attachments',
      customWidget: true,
      name: 'Attachments',
      uiKey: 'customWidget.attachments',
    },
    hasWidgetsWithBridged && {
      id: 'visit_history',
      customWidget: true,
      name: 'Visit history',
      uiKey: 'customWidget.visit_history',
    },
    isCustomProfileInstalled && {
      id: 'custom_profile',
      customWidget: true,
      name: 'Custom profile',
      uiKey: 'customWidget.custom_profile',
    },
  ].filter(widget => !!widget)

function getUiKey(object) {
  const { id, key, provider, uiKey } = object
  return (
    uiKey ||
    (provider
      ? `integration.${provider.toLowerCase()}.${id}`
      : `customFieldCategory.${key}`)
  )
}

const CHAT_COMPATIBLE_INTEGRATIONS = [
  'JIRA_CLOUD',
  'CAMPAIGN_MONITOR',
  'CONSTANT_CONTACT',
  'GITHUB',
  'JIRA_SERVER',
  'MAILCHIMP',
  'SHOPIFY',
  'STRIPE',
  'SALESFORCE',
  'TRELLO',
  'HUBSPOT',
  'SHOPIFY_V2',
  'RECHARGE',
]

export const selectContactDetailsColumnWidgetCardsByKey = createSelector(
  selectCustomFieldCategories,
  selectChannelCustomFieldCategoryGroups,
  selectSidebarCardIntegrations,
  selectIsInChat,
  selectAccountPreferenceCustomProfileAppInstalled,
  selectAccountPreferenceChatEnabled,
  selectIsChatEnabled,
  selectHasWidgetsWithBridged,
  selectCurrentConversationIsSpam,
  selectPrefersAiEnabled,
  (
    customFieldCategories,
    channelCategories,
    integrations,
    isChatPage,
    isCustomProfileInstalled,
    isChatEnabled,
    hasChatWidgets,
    hasWidgetsWithBridged,
    currentTicketIsSpam,
    isAiEnabled
  ) => {
    const categories = [...customFieldCategories]
    channelCategories.forEach(category => {
      if (categories.some(c => c.key === category.key)) return

      categories.push(category)
    })

    const original = [
      ...integrations.filter(({ provider, type }) => {
        return (
          !isChatPage || CHAT_COMPATIBLE_INTEGRATIONS.includes(provider || type)
        )
      }),
      ...categories,
      ...makeCustomWidgets(
        isChatEnabled,
        isChatPage,
        isCustomProfileInstalled,
        hasChatWidgets,
        hasWidgetsWithBridged,
        currentTicketIsSpam,
        isAiEnabled
      ),
    ].filter(x => !!x)
    return original.reduce((result, item) => {
      const uiKey = getUiKey(item)
      // eslint-disable-next-line no-param-reassign
      result[uiKey] = {
        ...item,
        uiKey,
      }
      return result
    }, {})
  }
)

export const selectContactDetailsContactInformationCard = createSelector(
  selectContactDetailsColumnWidgetCardsByKey,
  categories => {
    return categories['customFieldCategory.contact_information']
  }
)

export const selectConversationCustomFieldsCard = createSelector(
  selectContactDetailsColumnWidgetCardsByKey,
  selectCurrentConversationCustomFieldGroupKey,
  (categories, categoryKey) => {
    if (!categoryKey) return null
    return categories[`customFieldCategory.${categoryKey}`]
  }
)

export const selectContactDetailsColumnWidgetCards = createSelector(
  selectContactDetailsColumnWidgetCardsByKey,
  byKey => Object.values(byKey)
)

export const selectAreCustomFieldsLoading = createSelector(
  selectAllCustomFieldCategoriesIsLoading,
  selectAllCustomFieldsIsLoading,
  selectCurrentContactIsPartiallyLoaded,
  (categories, fields, contact) => categories || fields || !contact
)

export const selectAreCompanyCustomFieldsLoading = createSelector(
  selectCurrentCompanyIsPartiallyLoaded,
  selectCurrentContact,
  selectCurrentCompanyId,
  (company, contact, companyId) =>
    !company && contact?.companies?.length > 0 && companyId
)

export const selectDefaultContactDetailsColumnWidgetCardOrderAndVisibility = createSelector(
  selectContactDetailsColumnWidgetCards,
  cards => {
    return uniq([
      ...Object.keys(DEFAULT_ORDER),
      ...cards.map(({ uiKey }) => uiKey),
    ]).map((key, index) => ({
      key,
      index,
      visible: DEFAULT_ORDER[key] === undefined ? true : DEFAULT_ORDER[key],
    }))
  }
)

export const selectDefaultPriorityContactDetailsColumnWidgetCardOrderAndVisibility = createSelector(
  () => {
    return uniq([...Object.keys(DEFAULT_PRIORITY_ORDER)]).map((key, index) => ({
      key,
      index,
      visible:
        DEFAULT_PRIORITY_ORDER[key] === undefined
          ? true
          : DEFAULT_PRIORITY_ORDER[key],
    }))
  }
)
